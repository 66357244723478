<template>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 36 36" fill="#000">
        <g>
            <g class="st0">
                <path d="M8.6,35c-0.2,0-0.3-0.1-0.4-0.2c-0.3-0.5-7.7-12.9-7.7-17.2s3.6-8.1,8.1-8.1c4.5,0,8.1,3.6,8.1,8.1S9.4,34.2,9,34.7
    			C9,34.9,8.8,35,8.6,35z M8.6,10.4c-3.9,0-7.2,3.2-7.2,7.1c0,3.5,5.6,13.3,7.2,16c1.6-2.7,7.2-12.6,7.2-16
    			C15.8,13.6,12.6,10.4,8.6,10.4z M8.6,21.5c-2.2,0-4-1.8-4-3.9c0-2.2,1.8-3.9,4-3.9s4,1.8,4,3.9C12.6,19.7,10.8,21.5,8.6,21.5z
    			 M8.6,14.5c-1.7,0-3,1.3-3,3s1.3,3,3,3c1.7,0,3-1.3,3-3C11.6,15.9,10.3,14.5,8.6,14.5z" />
                <path d="M29.3,18.7c-0.2,0-0.3-0.1-0.4-0.2c-0.2-0.4-5.5-9.2-5.5-12.2c0-3.2,2.6-5.8,5.9-5.8c3.2,0,5.9,2.6,5.9,5.8
    			c0,3.2-5.2,11.8-5.5,12.2C29.6,18.6,29.5,18.7,29.3,18.7L29.3,18.7z M29.3,1.4c-2.7,0-4.9,2.2-4.9,4.9c0,2.1,3.2,8.1,4.9,11
    			c1.7-2.9,4.9-8.9,4.9-11C34.2,3.5,32,1.4,29.3,1.4z M29.3,9.2c-1.6,0-2.9-1.3-2.9-2.9s1.3-2.9,2.9-2.9s2.9,1.3,2.9,2.9
    			S31,9.2,29.3,9.2z M29.3,4.3c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2c1.1,0,2-0.9,2-2C31.3,5.2,30.4,4.3,29.3,4.3z" />
                <path d="M29.6,18.3l-0.6-0.1c0-0.1,0-0.3,0-0.4h0.7C29.6,18,29.6,18.2,29.6,18.3z" />
                <path d="M28.9,19.6l-0.5-0.4L28.9,19.6z" />
                <path d="M29.7,35.3h-0.3v-0.6h0.3c0.2,0,0.4,0,0.6,0l0.1,0.3l0-0.3l0.1,0.6C30.2,35.3,29.9,35.3,29.7,35.3L29.7,35.3z M28.1,35.3
    			h-1v-0.6h1L28.1,35.3L28.1,35.3z M25.9,35.3h-1v-0.6h1L25.9,35.3L25.9,35.3z M23.6,35.3h-1v-0.6h1L23.6,35.3L23.6,35.3z
    			 M21.4,35.3h-1v-0.6h1L21.4,35.3L21.4,35.3z M19.1,35.3h-1v-0.6h1L19.1,35.3L19.1,35.3z M16.9,35.3h-1v-0.6h1L16.9,35.3L16.9,35.3
    			z M14.7,35.3h-1v-0.6h1L14.7,35.3L14.7,35.3z M12.4,35.3h-1v-0.6h1L12.4,35.3L12.4,35.3z M10.2,35.3L10.2,35.3l0-0.6L10.2,35.3z
    			 M31.7,34.8l-0.3-0.6c0.3-0.1,0.5-0.3,0.7-0.5l0,0l0.2,0.3l0.2,0.2L32.4,34l0,0l0.2,0.2C32.3,34.4,32,34.6,31.7,34.8L31.7,34.8z
    			 M33.5,33.2l-0.6-0.3c0.2-0.3,0.3-0.5,0.4-0.8l0.6,0.2l-0.2-0.1l0.2,0.1C33.8,32.5,33.7,32.9,33.5,33.2L33.5,33.2z M33.6,32.1
    			l0.3,0.1L33.6,32.1z M33.5,30.9c0-0.3,0-0.6-0.1-0.9l0.3-0.1h0l0.3-0.1v0c0.1,0.3,0.1,0.7,0.1,1L33.5,30.9L33.5,30.9z M32.9,28.9
    			c-0.2-0.2-0.3-0.5-0.5-0.7l0,0l0.3-0.2l0.2-0.3L32.6,28l0,0l0.2-0.2c0.2,0.2,0.5,0.5,0.6,0.8L32.9,28.9L32.9,28.9z M31.4,27.5
    			c-0.3-0.1-0.5-0.2-0.8-0.3l0,0l0.1-0.3l0-0.3l0,0.3v0l0.1-0.3c0.3,0.1,0.7,0.2,1,0.4L31.4,27.5L31.4,27.5z M29.4,27.1l-1,0v-0.2
    			v-0.4l1,0L29.4,27.1L29.4,27.1z M27.1,27.1h-1v-0.3l0,0.3l0-0.6h1L27.1,27.1z M24.9,27.1l-1,0v-0.2v-0.4l1,0L24.9,27.1L24.9,27.1z
    			 M22.7,27.1h-1v-0.2v-0.4l1,0L22.7,27.1L22.7,27.1z M20.3,26.9c-0.3-0.1-0.6-0.3-0.9-0.5l0.2-0.3l0,0l0.2-0.3l0,0
    			c0.2,0.2,0.5,0.3,0.8,0.4L20.3,26.9L20.3,26.9z M18.4,25.4c-0.2-0.3-0.3-0.6-0.4-1l0.6-0.2l-0.2,0.1l0.2-0.1l-0.3,0.1l0.3-0.1
    			c0.1,0.3,0.2,0.5,0.3,0.8L18.4,25.4L18.4,25.4z M18.6,23.1L17.9,23c0-0.3,0.1-0.7,0.3-1l0.3,0.1h0l0.3,0.1l0,0
    			C18.7,22.5,18.6,22.8,18.6,23.1L18.6,23.1z M19.4,21.4L19,20.9c0.3-0.2,0.5-0.4,0.9-0.6l0.1,0.3l-0.1-0.3l0.3,0.6
    			C19.9,21,19.7,21.2,19.4,21.4L19.4,21.4z M21.2,20.5l-0.1-0.6c0.1,0,0.2,0,0.3,0h0.7v0.6h-0.7C21.4,20.5,21.3,20.5,21.2,20.5
    			L21.2,20.5z M26.7,20.5h-1v-0.6h1L26.7,20.5L26.7,20.5z M24.4,20.5h-1v-0.6h1L24.4,20.5L24.4,20.5z M28.1,20.3l-0.3-0.6
    			c0.2-0.1,0.5-0.3,0.7-0.5l0.5,0.4C28.7,19.9,28.4,20.1,28.1,20.3L28.1,20.3z" />
                <path d="M10.1,34.6H9.6v0.6h0.5V34.6z" />
            </g>
        </g>
    </svg>
</template>