<template>
    <svg version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
        y="0px" viewBox="0 0 34 33" fill="#000">
        <g>
            <g>
                <path
                    d="M23.5,9.5c-0.4,0-0.7-0.3-0.7-0.7v-3c0-0.4,0.3-0.7,0.7-0.7s0.7,0.3,0.7,0.7v3C24.2,9.2,23.9,9.5,23.5,9.5z" />
            </g>
            <g>
                <path d="M28,6h-9c-0.4,0-0.7-0.3-0.7-0.7v-4c0-0.4,0.3-0.7,0.7-0.7h9c0.4,0,0.7,0.3,0.7,0.7v4C28.6,5.7,28.4,6,28,6z M19.7,4.7
                			h7.7V2.1h-7.7V4.7z" />
            </g>
            <g>
                <path
                    d="M16.5,19.9h-11c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7h11c0.4,0,0.7,0.3,0.7,0.7S16.9,19.9,16.5,19.9z" />
            </g>
            <g>
                <path
                    d="M15.5,19.9c-0.4,0-0.7-0.3-0.7-0.7V6.8l-1.6,0.6c-0.2,0.1-0.4,0.1-0.5,0L11,6.6L9.3,7.4c-0.2,0.1-0.4,0.1-0.5,0L7.2,6.8
                			v12.4c0,0.4-0.3,0.7-0.7,0.7s-0.7-0.3-0.7-0.7V5.9c0-0.2,0.1-0.4,0.3-0.6c0.2-0.1,0.4-0.2,0.6-0.1L9,6.1l1.7-0.9
                			c0.2-0.1,0.4-0.1,0.6,0L13,6.1l2.2-0.9c0.2-0.1,0.4-0.1,0.6,0.1c0.2,0.1,0.3,0.3,0.3,0.6v13.4C16.2,19.6,15.9,19.9,15.5,19.9z" />
            </g>
            <g>
                <path d="M33.1,26.9h-1.3v-2.6l-2-7.4c0-0.1,0-0.1,0-0.2V9.5h-13V8.2h13.7c0.4,0,0.7,0.3,0.7,0.7v7.9l2,7.4c0,0.1,0,0.1,0,0.2V26.9
                			z" />
            </g>
            <g>
                <path
                    d="M2.2,26.9H0.8v-2.7c0-0.1,0-0.1,0-0.2l2-7.9V8.8c0-0.4,0.3-0.7,0.7-0.7h1.7v1.3h-1v6.8c0,0.1,0,0.1,0,0.2l-2,7.9V26.9z" />
            </g>
            <g>
                <path d="M20,29.3h-6c-1,0-1.7-0.7-1.7-1.7c0-0.4,0.3-0.7,0.7-0.7s0.7,0.3,0.7,0.7c0,0.2,0.1,0.3,0.3,0.3h6c0.2,0,0.3-0.1,0.3-0.3
                			c0-0.4,0.3-0.7,0.7-0.7s0.7,0.3,0.7,0.7C21.7,28.6,21,29.3,20,29.3z" />
            </g>
            <g>
                <path d="M28,15.5h-9c-0.4,0-0.7-0.3-0.7-0.7v-3c0-0.4,0.3-0.7,0.7-0.7h9c0.4,0,0.7,0.3,0.7,0.7v3C28.6,15.2,28.4,15.5,28,15.5z
                			 M19.7,14.1h7.7v-1.6h-7.7V14.1z" />
            </g>
            <g>
                <path d="M31.5,32.8h-29c-0.9,0-1.7-0.7-1.7-1.7v-6.5c0-0.9,0.7-1.7,1.7-1.7h29c0.9,0,1.7,0.7,1.7,1.7v6.5
                			C33.1,32.1,32.4,32.8,31.5,32.8z M2.5,24.4c-0.2,0-0.3,0.1-0.3,0.3v6.5c0,0.2,0.1,0.3,0.3,0.3h29c0.2,0,0.3-0.1,0.3-0.3v-6.5
                			c0-0.2-0.1-0.3-0.3-0.3H2.5z" />
            </g>
            <g>
                <path d="M21,18.4h-1c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7h1c0.4,0,0.7,0.3,0.7,0.7S21.4,18.4,21,18.4z" />
            </g>
            <g>
                <path d="M24,18.4h-1c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7h1c0.4,0,0.7,0.3,0.7,0.7S24.4,18.4,24,18.4z" />
            </g>
            <g>
                <path d="M27,18.4h-1c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7h1c0.4,0,0.7,0.3,0.7,0.7S27.4,18.4,27,18.4z" />
            </g>
            <g>
                <path d="M21,21.4h-1c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7h1c0.4,0,0.7,0.3,0.7,0.7S21.4,21.4,21,21.4z" />
            </g>
            <g>
                <path d="M24,21.4h-1c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7h1c0.4,0,0.7,0.3,0.7,0.7S24.4,21.4,24,21.4z" />
            </g>
            <g>
            <path d="M27,21.4h-1c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7h1c0.4,0,0.7,0.3,0.7,0.7S27.4,21.4,27,21.4z" />
        </g>
    </g>
</svg></template>