<template>
    <svg version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000" x="0px"
        y="0px" viewBox="0 0 40 39">
        <g>
            <path d="M24.2,38.9c-0.4,0-0.8-0.4-0.8-0.8c0-0.1,0-2.3,1.7-3.1c0.4-0.2,0.9,0,1.1,0.4c0.2,0.4,0,0.9-0.4,1.1
                c-0.7,0.4-0.8,1.7-0.8,1.7C25,38.6,24.7,38.9,24.2,38.9z M21.8,38.9c-0.4,0-0.8-0.4-0.8-0.8V19.5c0-0.4,0.4-0.8,0.8-0.8
                s0.8,0.4,0.8,0.8v18.6C22.6,38.6,22.2,38.9,21.8,38.9z M17.6,38.9c-0.4,0-0.8-0.4-0.8-0.8V11.9H8.2l-5.5,4l5.5,4h5.8
                c0.4,0,0.8,0.4,0.8,0.8s-0.4,0.8-0.8,0.8h-6c-0.2,0-0.3-0.1-0.5-0.2l-6.7-4.8c-0.2-0.2-0.3-0.4-0.3-0.7s0.1-0.5,0.3-0.7l6.7-4.8
                c0.1-0.1,0.3-0.2,0.5-0.2h8.9V3c0-1.6,1.3-2.9,2.9-2.9c1.6,0,2.9,1.3,2.9,2.9v2.5h9.5c0.2,0,0.3,0.1,0.5,0.2l6.6,4.8
                c0.2,0.2,0.3,0.4,0.3,0.7s-0.1,0.5-0.3,0.7l-6.6,4.8c-0.1,0.1-0.3,0.2-0.5,0.2H20.6c-0.4,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8h11.2
                l5.5-4l-5.5-4H20.6c-0.4,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8H21V3c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v35.1
                C18.4,38.6,18,38.9,17.6,38.9z M15.2,38.9c-0.4,0-0.8-0.4-0.8-0.8c0,0,0-2-1.5-2.9c-0.4-0.2-0.5-0.7-0.2-1.1
                c0.2-0.4,0.7-0.5,1.1-0.2c2.2,1.4,2.2,4.2,2.2,4.3C16,38.6,15.6,38.9,15.2,38.9z" />
        </g>
    </svg>
</template>